import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Subscribe from '../../components/Subscribe/Subscribe';
import '../../assets/css/GalleryImage.css'
import { aboutBanner, gallery_one, gallery_two, gallery_three, gallery_four, gallery_five } from '../../constant';

const GalleryImages = () => {

    return (
        <div className='gallery_page'>
            <Header bg={false} />
            <div className='banner about'>
                <img src={aboutBanner} alt="" className='image-fluid image-width' />
                <div className='banner-contentAbout'>
                    <div className='banner-inner'>
                        <h2 className='banner-titleAbout'>Gallery</h2>
                    </div>
                </div>
            </div>

            <section className='gallery_image_page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 pr-0'>
                            <img src={gallery_one} className="w-100" alt="gallery content" />
                            <img src={gallery_two} className="mt-3 w-100" alt="gallery content" />
                        </div>
                        <div className='col-lg-4 pr-0'>
                            <img src={gallery_three} className="h-100 w-100" alt="gallery content" />
                        </div>
                        <div className='col-lg-5 '>
                            <img src={gallery_four} className="h-100 w-100" alt="gallery content" />
                        </div>
                        <div className='col-lg-12 mt-3'>
                            <img src={gallery_five} className="w-100" alt="gallery content" />
                        </div>
                    </div>
                </div>
            </section>

            <Subscribe />
            <Footer />
        </div>
    )
}

export default GalleryImages;