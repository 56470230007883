import React from "react";
import Slider from "react-slick";
import { p1, p2, p3, three_sixty_ourprod } from "../../constant";

function OurProductsSlider() {
  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className="Slider_prod_custom_arrows-next"
        // style={{ ...style, display: "block", background: "red", height: "180px", width: "180px" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className="Slider_top_custom_arrows"
        // style={{ ...style, display: "block", background: "green", height: "180px", width: "180px" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    // arrows: false,
    dots: true,
    infinite: true,
    fade: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const BannerData = [
    {
      id: 1,
      img: p1,
      title: "Meet the world's ",
      sub: "coolest E-Bikes",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 2,
      img: p2,
      title: "Meet the world's ",
      sub: "coolest E-Bikes",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 3,
      img: p3,
      title: "Meet the world's ",
      sub: "coolest E-Bikes",
      desc: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ];
  return (
    <div className="Banner-container">
      <Slider {...settings}>
        {BannerData?.map((item, index) => {
          console.log("item fromhome banner", item.img);
          return (
            <div key={index}>
              <div
                style={{ backgroundImage: `url("${item?.img}")` }}
                // className=' '
                className="banner-slider setbnr_imgs"
                key={index}
              >
                <p className="main-para">Find your dream bike here</p>
                <h2 className="main-title">Our Products</h2>
                {/* <img src={item?.img} className="image-fluid" alt="" /> */}
                <div className="contentDv">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="content-inner">
                        <h2 className="title">{item?.title}</h2>
                        <h2 className="sub-title">{item?.sub}</h2>
                        <hr className="hr-line" />
                        <p className="para">
                          {item?.desc} <br />
                          {/* <span>Read More</span> */}
                        </p>
                        {/* <div className="mt-3">
                      <button type="button" className="btn btn-primary">
                        Shop Now
                      </button>
                    </div> */}
                        <div className="threesixsty_div">
                          <img
                            className="three_sixty_ourprod"
                            src={three_sixty_ourprod}
                            height={20}
                            alt="360_prod"
                          />{" "}
                          <span className="threesixsty_txt">360 View</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
                <div className="view_detials_ourproduct">
                  <button className="btn btn-light btn_view_details">
                    {" "}
                    View Details
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default OurProductsSlider;
