import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { aboutBanner, Userpic } from "../../constant";
import "../../assets/css/ProductDetails.css";
import { Rating } from "react-simple-star-rating";
import ProductDetailSlider from "../../components/OurProducts/ProductDetailSlider";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { feature1 } from "../../constant";
import { feature2 } from "../../constant";
import { Link } from "react-router-dom";

function ProductDetails() {
  const allReviewData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  return (
    <>
      <Header bg={false} />
      <div className="banner about">
        <img src={aboutBanner} alt="" className="image-fluid image-width" />
        <div className="banner-contentAbout">
          <div className="banner-inner">
            <h2 className="banner-titleAbout">Product Details</h2>
          </div>
        </div>
      </div>

      <section className="productDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ProductDetailSlider />
            </div>
            <div className="col-md-6">
              <h3 className="main-title">E- Bike #106</h3>
              <p className="main-para">
                Hella pop-up flexitarian, semiotics migas humblebrag schlitz
                literally tofu deep v thundercats skateboard viral cornhole.
                Lomo knausgaard truffaut selfies flexitarian.
              </p>
              <div className="rating-container">
                <Rating
                  size={28}
                  readonly={true}
                  ratingValue={60}
                  //   ratingValue={
                  //     Math.round(item?.average) === 1
                  //       ? "20"
                  //       : Math.round(item?.average) === 2
                  //       ? "40"
                  //       : Math.round(item?.average) === 3
                  //       ? "60"
                  //       : Math.round(item?.average) === 4
                  //       ? "80"
                  //       : Math.round(item?.average) === 5
                  //       ? "100"
                  //       : null
                  //   }
                />
                <p className="review-count">( 2 reviews )</p>
              </div>
              <div>
                {/* <p className="cate-text">Category : City</p> */}
                <div className="select-Container">
                  <p className="select-p">Category</p>
                  <select>
                    <option value="0">City</option>
                    <option value="1">E-Mountaineering</option>
                    <option value="2">E-Urban</option>
                  </select>
                </div>
                <div>
                  <div className="select-Container">
                    <p className="select-p">Select Model</p>
                    <select>
                      <option value="0">Select Model Here</option>
                      <option value="1">Audi</option>
                      <option value="2">BMW</option>
                    </select>
                  </div>

                  <div className="select-Container">
                    <p className="select-p">Select Size</p>
                    <select>
                      <option value="0">Select Size</option>
                      <option value="1">Audi</option>
                      <option value="2">BMW</option>
                    </select>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <div className="select-Container">
                        <p className="select-p">Select Color</p>
                        <select>
                          <option value="0">Select Color</option>
                          <option value="1">Audi</option>
                          <option value="2">BMW</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="quantity">
                        <label>Select Quantity</label>
                        <div className="d-flex">
                          <div className="qty-container">
                            <p>1</p>
                          </div>
                          <div style={{ display: "grid" }}>
                            <button className="btn qty-arrow">
                              <FontAwesomeIcon
                                icon={faAngleUp}
                                className="icon_rating"
                              />
                            </button>
                            <button className="btn qty-arrow">
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="icon_rating"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detail-actionContainer">
                {/* <div className="d-flex">
                  <div className="qty-container">
                    <p className="mt-3">1</p>
                  </div>
                  <div style={{ display: "grid" }}>
                    <button className="btn qty-arrow">
                      <FontAwesomeIcon
                        icon={faAngleUp}
                        className="icon_rating"
                      />
                    </button>
                    <button className="btn qty-arrow">
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="icon_rating"
                      />
                    </button>
                  </div>
                </div> */}

                <div className="detail-actionContainer">
                  <div className="evaluation">
                    <div className="form-group">
                      <label>Price Evaluation</label>
                      <h4 className="price">$500.00</h4>
                    </div>
                  </div>
                  <div>
                    <Link to="/cart" className="btn btn-details">
                      Add to cart
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="product-review">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-description-tab"
                  data-toggle="pill"
                  href="#pills-description"
                  role="tab"
                  aria-controls="pills-description"
                  aria-selected="true"
                >
                  Description
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-reviews-tab"
                  data-toggle="pill"
                  href="#pills-reviews"
                  role="tab"
                  aria-controls="pills-reviews"
                  aria-selected="false"
                >
                  Reviews
                </a>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-description"
                role="tabpanel"
                aria-labelledby="pills-description-tab"
              >
                <div className="description_content">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="wrapper">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                          <br />
                          <br />
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum dolore eu fugiat nulla pariatur.
                          Excepteur sint occaecat cupidatat non proident, sunt
                          in culpa qui officia deserunt mollit anim id est
                          laborum.There are many variations of passages of Lorem
                          Ipsum available, but the majority have suffered
                          alteration in some form, by injected humour, or
                          randomised words which don't look even slightly
                          believable.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="featureDv">
                        <div className="heading">
                          <h4>Features</h4>
                        </div>
                        <div className="feature_box">
                          <div className="img_box">
                            <figure>
                              <img className="img-fluid" src={feature1} />
                            </figure>
                          </div>
                          <div className="contentDv">
                            <h3>Lorem Ipsum</h3>
                            <p>
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since
                            </p>
                          </div>
                        </div>
                        <div className="feature_box">
                          <div className="img_box">
                            <figure>
                              <img className="img-fluid" src={feature2} />
                            </figure>
                          </div>
                          <div className="contentDv">
                            <h3>Lorem Ipsum</h3>
                            <p>
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since
                            </p>
                          </div>
                        </div>
                        <div className="feature_box">
                          <div className="img_box">
                            <figure>
                              <img className="img-fluid" src={feature1} />
                            </figure>
                          </div>
                          <div className="contentDv">
                            <h3>Lorem Ipsum</h3>
                            <p>
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-reviews"
                role="tabpanel"
                aria-labelledby="pills-reviews-tab"
              >
                <div className="row">
                  <div className="col-md-7">
                    <div className="All-Reviews">
                      <div className="row review-card">
                        {allReviewData?.map((item, index) => {
                          return (
                            <>
                              <div className="col-md-2 mt-5 mb-5" key={index}>
                                <div className="profile">
                                  <img
                                    src={Userpic}
                                    alt=""
                                    className="image-fluid image-width"
                                  />
                                </div>
                              </div>
                              <div className="col-md-10 mt-5 mb-5">
                                <div>
                                  <p className="date">
                                    22 SEP 2021 BY PETER EVANS
                                  </p>
                                  <p className="details">
                                    Pabst irony tattooed, synth sriracha selvage
                                    pok pok. Wayfarers kinfolk sartorial,
                                    helvetica you probably haven't heard of them
                                    tumeric venmo deep v mixtape semiotics
                                    brunch.
                                  </p>
                                  <div className="review-rating">
                                    <Rating
                                      size={22}
                                      readonly={true}
                                      ratingValue={60}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 mt-5">
                    <div className="post-review">
                      <h4 className="add-review">ADD A REVIEW </h4>
                      <div className="input-container">
                        <p className="label">Name *</p>
                        <input type="text" placeholder="Name Here" />
                      </div>
                      <div className="input-container">
                        <p className="label">Email*</p>
                        <input type="text" placeholder="Email" />
                      </div>
                      <div className="input-container">
                        <p className="label">Your Review *</p>
                        <input type="text" placeholder="Review" />
                      </div>
                      <div>
                        <button className="btn post-btn">Post</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductDetails;
