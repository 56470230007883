import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Subscribe from '../../components/Subscribe/Subscribe';

const Signup = () => {
    return (
        <div className='Auth'>
            <Header bg={true} />
            <section className='Auth_top_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 text-center my-4'>
                            <p className='main_auth_heading'>Sign In</p>
                            <p className='main_auth_text'>Already have an account? <span className='main_auth_text_span'>Sign In</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='Auth_form_section py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='offset-lg-2 col-lg-8'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <p className='auth_label'>First Name</p>
                                    <input type='text' className='auth_inpt_txt' placeholder='Enter First Name Here' />
                                    <p className='auth_label'>Email</p>
                                    <input type='text' className='auth_inpt_txt' placeholder='Enter Email Here' />
                                    <p className='auth_label'>Password</p>
                                    <input type='password' className='auth_inpt_txt' placeholder='Enter Password Here' />
                                </div>
                                <div className='col-lg-6'>
                                    <p className='auth_label'>Last Name</p>
                                    <input type='text' className='auth_inpt_txt' placeholder='Enter Last Name Here' />
                                    <p className='auth_label'>Phone Number</p>
                                    <input type='text' className='auth_inpt_txt' placeholder='Enter Phone Number Here' />
                                    <p className='auth_label'>Confirm Password</p>
                                    <input type='password' className='auth_inpt_txt' placeholder='Enter Password Here' />
                                </div>
                            </div>


                            <button className='btn btn-light btn-auth mt-5'>
                                Sign Up
                            </button>

                            <div className='auth_flx justify-center mt-4'>
                                <p>By Creating Account  you are agree to our</p>
                                <p className='ml-4'>Terms & conditions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe />
            <Footer />
        </div>
    );
};

export default Signup;