import React from "react";
import { cartp, Userpic } from "../../constant";

function CartList(props) {
  const { 
    item,
    index,
    // RemoveCartItem,
    // quantity,
    // setQuantity,
    // Increment,
    // Decrement,
  } = props;
//   const Token = useSelector((state) => state.AuthReducer.token);
//   const UserRedux = useSelector((state) => state.AuthReducer.users);

  console.log(item);
  return (
    <>
      <tr className="products cart" key={item?.id}>
        <td className="productDetail">
          <div className="product-detail">
            <div 
              className="deleteBtn"
            //   onClick={() => RemoveCartItem(item?.productitem?.id)}
              >
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <div className="imgBox">
              <figure>
                {/* <img
                  src={`${item?.productitem?.thumbnail_url}`}
                  alt=""
                  className="image-fluid image-width"
                /> */}
                 <img
                  src={cartp}
                  alt=""
                  className="image-fluid image-width"
                />
              </figure>
            </div>
            <div className="name">
              <p className="font-13">{item?.name}</p>
            </div>
          </div>
        </td>
        <td className="priceText">
          <h5 className="font-15">${item?.unit_price}</h5>
        </td>
        <td className="qty">
          <div className="quantity-inner">
            <button 
              className="btn increment-Decrement mr-2"
            //   onClick={() => Increment(item?.id)}
            >
              +
            </button>
            1
            <button 
              className="btn increment-Decrement ml-2"
            //   onClick={() => Decrement(item?.id)}
            >
              -
            </button>
          </div>
        </td>
        <td className="addcart">
          <div>
            <div className="cartBtn">
            <p>${ item?.unit_price}</p>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default CartList;
