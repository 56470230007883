import React, { useState } from "react";
import "../../assets/css/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMagnifyingGlass,
  faHeart,
  faCartShopping,
  faUser,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { Link, Outlet, useNavigate } from "react-router-dom";
import { headlogo, profile_img } from "../../constant";

function Header(props) {
  const { bg } = props;
  const Navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleCollapse = () => {
    console.log("collapse", collapse);
    setCollapse(!collapse);
  };

  const [search, setSearch] = useState(false);

  const isLogin = false;

  return (
    <>
      <div>
        <section
          className={`header nav-bottom ${bg ? "nav-color header2" : "null"}`}
        >
          <nav className="navbar navbar-dark navbar-expand-md navigation-clean-search">
            <div className="container">
              <Link className="navbar-brand navbar-left" to="/">
                <img
                  src={headlogo}
                  target="_self"
                  className="logo-width head-logo"
                  alt=""
                />
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleCollapse}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={"collapse navbar-collapse " + (collapse && "show")}
                id="navbarNavDropdown"
              >
                {/* <ul className={`nav navbar-nav navbar-left${bg ? "header-und" : "header-n"}`}> */}
                <ul className="nav navbar-nav navbar-left">
                  <li className="nav-item pl-0" role="presentation">
                    <Link to="/" className="nav-link pl-0">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/features" className="nav-link">
                      Features
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/shop" className="nav-link">
                      Shop
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/" className="nav-link">
                      Gallery
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/contact-us" className="nav-link">
                      Contact
                    </Link>
                  </li>
                  <li className="icon_items" role="presentation">
                    <Link to="/my-wishlist" className="nav-link">
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="social-color icon-style"
                      />
                    </Link>
                    <a className="nav-link">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="social-color icon-style"
                        onClick={() => setSearch(!search)}
                      />
                    </a>
                    <Link to="/cart" className="nav-link">
                      <FontAwesomeIcon
                        icon={faCartShopping}
                        className="social-color icon-style"
                      />
                    </Link>
                    <Link to="/profile" className="nav-link">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="social-color icon-style"
                      />
                    </Link>
                    <Link to="/profile" className="nav-link">
                      <img
                        className="img_profile"
                        src={profile_img}
                        alt="profile_image"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="social-color icon-style icon_dropdown"
                      />
                    </Link>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    
                  </li>
                  <li className="nav-item" role="presentation">
                   
                  </li>
                  <li className="nav-item" role="presentation">
                   
                  </li>
                  <li className="nav-item" role="presentation">
                 
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </section>
      </div>
      <div
        className={search ? "search-wrapper search-wrapper2" : "search-wrapper"}
      >
        <div className="button-group">
          <button onClick={() => setSearch(!search)}>X</button>
        </div>
        <div className="search-box">
          <input type="search" className="Search" placeholder="Search" />
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Header;
