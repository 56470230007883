import React from "react";
import "../../assets/css/LatestNews.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  aboutBanner,
  letest_news_1,
  letest_news_2,
  letest_news_3,
  letest_news_4,
} from "../../constant";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const LatestNewsPage = () => {
  let latestNews = [
    {
      image: letest_news_1,
      heading: "Sed ut perspiciatis unde omnis iste",
      date: "Nov 7 2021",
      desc: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.",
    },
    {
      image: letest_news_2,
      heading: "Sed ut perspiciatis unde omnis iste",
      date: "Nov 7 2021",
      desc: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.",
    },
    {
      image: letest_news_3,
      heading: "Sed ut perspiciatis unde omnis iste",
      date: "Nov 7 2021",
      desc: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.",
    },
    {
      image: letest_news_4,
      heading: "Sed ut perspiciatis unde omnis iste",
      date: "Nov 7 2021",
      desc: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.",
    },
  ];

  return (
    <div className="latestnews_page">
      <Header bg={false} />
      <div className="banner about">
        <img src={aboutBanner} alt="" className="image-fluid image-width" />
        <div className="banner-contentAbout">
          <div className="banner-inner">
            <h2 className="banner-titleAbout">Latest News</h2>
          </div>
        </div>
      </div>
      <div className="latestnews_content">
        <div className="container">
          <div className="row mx-5">
            {latestNews.map((item, index) => {
              return (
                <div className="col-lg-12 mb-5">
                  <div className="row">
                    <div className="col-lg-4 p-0">
                      <img className="prod_img" src={item.image} />
                    </div>
                    <div className="col-lg-8 p-0">
                      <div className="bg_gry_LatestNews">
                        <div className="top_head_div">
                          <p className="news_heading">{item.heading}</p>
                          <p>
                            <small className="clr_gr_date">
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className="social-color icon-style"
                              />{" "}
                              <span className="ml-2">{item.date}</span>
                            </small>
                          </p>
                        </div>
                        <p className="news_desc">{item.desc}</p>
                        <Link
                          to="/news-details"
                          className="cursor_pointer view_details"
                        >
                          View Detials
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-lg-12 mt-5 mb-2 text-center">
              <button className="btn btn-light bt_view_mores">View All</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LatestNewsPage;
