import React from 'react'

function AddAddress() {
  return (
    <div className='col-md-12 col-sm-12 col-12 AddAddress'>
    <div className='container'>
        <div className='row'>
            <div className='col-lg-9'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 mb-5'>
                        <span className='checkout_subheading mt-5'>Add New Address</span>
                    </div>
                    <div className='col-lg-12'>
                        <p className=''>Shipment Method</p>
                    </div>
                    <div className='col-lg-12 mb-4'>
                        <select className='form-control form-select select_field'>
                            <option>Select Shipment Method</option>
                            <option>Select Shipment Method</option>
                            <option>Select Shipment Method</option>
                        </select>
                    </div>
<div className='col-lg-6 mb-2'>
<p className='mt-3 checkout_label'>First Name</p>
<input type='text' placeholder='John' className="form-control inpt_fld" />
</div>
<div className='col-lg-6 mb-2'>
<p className='mt-3 checkout_label'>Last Name</p>
<input type='text' placeholder='Doe' className="form-control inpt_fld" />
</div>
<div className='col-lg-6 mb-4'>
<p className='mt-3 checkout_label'>Phone</p>
<input type='text' placeholder='+1 123 456 7890' className="form-control inpt_fld" />
</div>
<div className='col-lg-6 mb-4'>
<p className='mt-3 checkout_label'>Email</p>
<input type='text' placeholder='Johndoe@email.com' className="form-control inpt_fld" />
</div>
<div className='col-lg-12 mb-4'>
<p className='mt-3 checkout_label'>Address</p>
<input type='text' placeholder='Your Address Here' className="form-control inpt_fld" />
</div>
<div className='col-lg-12 mb-4'>
<p className='mt-3 checkout_label'>Suite,Apartment,etc</p>
<input type='text' placeholder='# 000' className="form-control inpt_fld" />
</div>
<div className='col-lg-12 mb-4'>
<p className='mt-3 checkout_label'>City</p>
<input type='text' placeholder='Your City Here' className="form-control inpt_fld" />
</div>
<div className='col-lg-4 mb-4'>
<p className='mt-3 checkout_label'>Country/Region</p>
<input type='text' placeholder='Country Here' className="form-control inpt_fld" />
</div>
<div className='col-lg-4 mb-4'>
<p className='mt-3 checkout_label'>State</p>
<input type='text' placeholder='State Here' className="form-control inpt_fld" />
</div>
<div className='col-lg-4 mb-4'>
<p className='mt-3 checkout_label'>Zip Code</p>
<input type='text' placeholder='Zip code Here' className="form-control inpt_fld" />
</div>
</div>
</div>
</div>
</div>
    </div>
  )
}

export default AddAddress