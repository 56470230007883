import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import '../../assets/css/Faqs.css'
import { aboutBanner } from '../../constant';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Header from '../../components/Header/Header';
import Subscribe from '../../components/Subscribe/Subscribe';
import Footer from '../../components/Footer/Footer';

const Faqs = () => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let faqs = [{
        faq_num: "faq1",
        faq_heading: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
        faq_desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    }, {
        faq_num: "faq2",
        faq_heading: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
        faq_desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    }, {
        faq_num: "faq3",
        faq_heading: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
        faq_desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    }, {
        faq_num: "faq4",
        faq_heading: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
        faq_desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    }, {
        faq_num: "faq5",
        faq_heading: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
        faq_desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    }]

    return (
        <div className='faqs'>
            <Header bg={false} />
            <div className='banner about'>
                <img src={aboutBanner} alt="" className='image-fluid image-width' />
                <div className='banner-contentAbout'>
                    <div className='banner-inner'>
                        <h2 className='banner-titleAbout'>FAQs</h2>
                    </div>
                </div>
            </div>
            {/* materail iu mui accordian starts*/}

            <div className='faqs_content'>
                <div className='container'>
                    <div className='row mb-5'>
                        {faqs.map((item, index) => {
                            return (
                                <div key={index} className='col-lg-12 '>
                                    <Accordion
                                        className={expanded === `${item.faq_num}` ? `accordian_all` : `accordian_allaccordian_all`}
                                        expanded={expanded === `${item.faq_num}`} onChange={handleChange(`${item.faq_num}`)}>
                                        <AccordionSummary
                                            className={expanded === `${item.faq_num}` ? `accordian_colapse` : `accordian_colapse_bgwht`}
                                            // className='accordian_colapse'
                                            expandIcon={expanded === `${item.faq_num}`
                                                ?
                                                <RemoveIcon className={expanded === `${item.faq_num}` ? `text-white` : `text-dark`} />
                                                :
                                                <AddIcon className={expanded === `${item.faq_num}` ? `text-white` : `text-dark`} />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <span className='m-0 faq_heading'>{item.faq_heading}</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='px-4 py-2'>
                                                <span className='faq_desc'>
                                                    {item.faq_desc}
                                                </span>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* materail iu mui accordian ends*/}
            <Subscribe />
            <Footer />
        </div>
    )
}

export default Faqs;