import React from 'react';
import Header from '../../components/Header/Header';
import { news_details_img, newsdetails_profile } from '../../constant/index';
import '../../assets/css/NewsDetails.css'
import Footer from '../../components/Footer/Footer';
import Subscribe from '../../components/Subscribe/Subscribe';

const NewsDetails = () => {
    let news_details = [{
        image: news_details_img,
        heading: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        text_one: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        text_two: "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        text_three: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        text_four: "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        text_five: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        text_six: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    }]

    let comments = [
        {
            profile_img: newsdetails_profile,
            user_name: "Joeby Ragpa",
            date: "12 April, 2014 at 16:50",
            no_comments: "2",
            meassages: "We possess within us two minds. So far I have written only of the conscious mind. I would now like to introduce you to your second mind, the hidden and mysterious subconscious. Our subconscious mind contains such power and complexity that it literally staggers the imagination.",
            reply_comments: [
                {
                    profile_img: newsdetails_profile,
                    user_name: "Joeby Ragpa",
                    date: "12 April, 2014 at 16:50",
                    meassages: "We possess within us two minds. So far I have written only of the conscious mind. I would now like to introduce you to your second mind, the hidden and mysterious subconscious. Our subconscious mind contains such power and complexity that it literally staggers the imagination.",
                }, {
                    profile_img: newsdetails_profile,
                    user_name: "Joeby Ragpa",
                    date: "12 April, 2014 at 16:50",
                    meassages: "We possess within us two minds. So far I have written only of the conscious mind. I would now like to introduce you to your second mind, the hidden and mysterious subconscious. Our subconscious mind contains such power and complexity that it literally staggers the imagination.",
                }, {
                    profile_img: newsdetails_profile,
                    user_name: "Joeby Ragpa",
                    date: "12 April, 2014 at 16:50",
                    meassages: "We possess within us two minds. So far I have written only of the conscious mind. I would now like to introduce you to your second mind, the hidden and mysterious subconscious. Our subconscious mind contains such power and complexity that it literally staggers the imagination.",
                }
            ]
        }, {
            profile_img: newsdetails_profile,
            user_name: "Joeby Ragpa",
            date: "12 April, 2014 at 16:50",
            no_comments: "2",
            meassages: "We possess within us two minds. So far I have written only of the conscious mind. I would now like to introduce you to your second mind, the hidden and mysterious subconscious. Our subconscious mind contains such power and complexity that it literally staggers the imagination.",
            reply_comments: undefined
        }
    ]
    return (
        <div className='NewsDetailsPage'>
            <Header bg={true} />
            <div className='Auth'>
                <section className='Auth_top_section'>
                    <div className='container'>
                        <div className='row py-3'>
                            <div className='col-lg-12 text-center my-4'>
                                <p className='main_auth_heading mb-0'>News Detail</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='NewsDetails_content'>
                <div className='container'>
                    {news_details.map((item, index) => {
                        return (
                            <div key={index} className='row'>
                                <div className='col-lg-12'>
                                    <img className='w-100' src={news_details_img} alt="news_details_img" />
                                </div>
                                <div className='col-lg-12'>
                                    <p className='news_details_main_heading my-4'>{item.heading}</p>
                                    <p className='text mb-5'>{item.text_one}</p>
                                    <p className='text mb-5'>{item.text_two}</p>
                                    <p className='text mb-5'>{item.text_three}</p>
                                    <p className='text mb-5'>{item.text_four}</p>
                                    <p className='text mb-5'>{item.text_five}</p>
                                    <p className='text mb-5'>{item.text_six}</p>
                                </div>
                            </div>
                        )
                    })}
                    <p className='news_details_main_heading'>Comment</p>
                    {comments.map((item, index) => {
                        return (
                            <div key={index} className='row'>
                                <div className='col-lg-12'>
                                    <div className='comment_box'>

                                        <p className='total_comments'>{item.no_comments} Comments</p>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='comment_sec'>
                                        <div className='row'>
                                            <div className='col-lg-1 pr-0'>
                                                <img className='w-100' src={item.profile_img} alt="commentator" />
                                            </div>
                                            <div className='col-lg-11'>
                                                <p className='profile_name'>
                                                    {item.user_name}<span className='mx-3'>-</span><span className='light_text'>{item.date}</span>
                                                </p>
                                                <p className='msg_txt'>
                                                    {item.meassages}
                                                </p>
                                                <p className='msg_txt rply'>
                                                    Reply
                                                </p>
                                                {(item?.reply_comments) ? <div key={index} className='box_comment'>
                                                    <div className='square_greybox'>

                                                    </div>
                                                    {item?.reply_comments?.map((inneritem, index_inner) => {
                                                        return (
                                                            <div className='row'>
                                                                <div className='col-lg-1 pr-0'>
                                                                    <img className='w-100' src={inneritem.profile_img} alt="commentator" />
                                                                </div>
                                                                <div className='col-lg-11'>
                                                                    <p className='profile_name'>
                                                                        {inneritem.user_name}<span className='mx-3'>-</span><span className='light_text'>{item.date}</span>
                                                                    </p>
                                                                    <p className='msg_txt'>
                                                                        {inneritem.meassages}
                                                                    </p>
                                                                </div>

                                                                {/* {console.log('inner index',index_inner)} */}
                                                                {(index_inner == item.reply_comments?.length - 1)
                                                                    ?
                                                                    <></>
                                                                    :
                                                                    <div className='col-lg-12 mt-3 mb-4'>
                                                                        <div className='brdr-btm-light'>

                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </div> :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <div className='col-lg-12 mt-4'>
                        <p class="news_details_main_heading">Leave a Comment</p>
                    </div>
                    <div className='col-lg-7 mt-4'>
                        <div className='row'>
                            <div className='col-lg-6  mt-2'>
                                <input className='inpt_fld' placeholder='Name' />
                            </div>
                            <div className='col-lg-6 mt-2'>
                                <input className='inpt_fld' placeholder='Email' />
                            </div>
                            <div className='col-lg-12 mt-3'>
                                <textarea className='inpt_fld_txtarea' rows={4} placeholder="Comment" />
                            </div>
                            <div className='col-lg-12 mt-5 mb-4'>
                                <button className='btn btn-light bt-submit' >Submit</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Subscribe />
            <Footer />
        </div>
    )
}

export default NewsDetails;