import React from "react";
import Header from "../../components/Header/Header";
import "../../assets/css/Home.css";
import {
  banner1,
  newArrival_txt,
  fe1,
  fe2,
  fe3,
  feature_imgs,
  newArrival_btmline,
  newarivl_topline,
  new_line_b,
  feature_dot_one,
  LatestNews,
  homeN1,
  homeN2,
  htWork1,
  news1,
  news2,
} from "../../constant";

import OurProductsSlider from "../../components/OurProducts/OurProductsSlider";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HeaderBanner from "../../components/Slider/HeaderBanner";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Subscribe from "../../components/Subscribe/Subscribe";
import Upcoming_PreOrder from "../../components/Upcoming_PreOrder/Upcoming_PreOrder";

function Home(props) {
  const newsData = [
    {
      id: 1,
      img: news1,
      image_line: new_line_b,
    },
    {
      id: 2,
      img: news2,
      image_line: new_line_b,
    },
  ];
  return (
    <>
      <Header bg={false} />
      <div className="banner">
        <HeaderBanner />
        {/* <img src={banner1} alt="" className='image-fluid image-width' />
       <div className='banner-content'>
            <div className='banner-inner'>
                <h2 className='banner-rotateText'>Swift</h2>
                <h2 className='banner-title'>E Bikes</h2>
            </div>
            <hr className='hr-line'/>
            <div>
                <h4 className='banner-subTitle'> Premium Bikes</h4>
                <h4 className='banner-subTitle'>at Affordable Prices</h4>
            </div>
       </div> */}
      </div>
      {/* <HeaderBanner /> */}
      <section className="Home">
        <div className="Features">
          <div className="container">
            <h2 className="main-title">Features</h2>
            <p className="main-para">Find your dream bike here</p>
            <div className="row pos-rel">
              <div className="col-md-4 pos-rel pr-5">
                <img
                  className="feature_img_abs"
                  src={feature_imgs}
                  alt="background feature image"
                />
                <h3 className="title">Lorem Ipsum Dolor, Sit Amet</h3>
                <p className="para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <p className="para">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at Hampden-Sydney College in Virginia,
                  looked up one of the more obscure Latin words, consectetur,
                  from a Lorem Ipsum passage, and going through the cites of the
                  word in classical literature, discovered the undoubtable
                  source.
                </p>
                <div>
                  <button className="btn learn">Learn More</button>
                </div>
              </div>
              <div className="col-md-2  pl-5 ">
                <img
                  src={feature_dot_one}
                  className="feature_dot_one fit_img_threetuk"
                  alt="dots img"
                />
                <img
                  src={fe2}
                  alt=""
                  className="image-fluid image-width fit_img_threetuk"
                  style={{ height: "100%" }}
                />
              </div>
              <div className="col-md-4 feat-dv1">
                <img
                  src={fe1}
                  alt=""
                  className="image-fluid image-width fit_img_threetuk"
                  style={{ height: "100%" }}
                />
              </div>
              <div className="col-md-2 pr-5 feat-dv1">
                <img
                  src={fe3}
                  alt=""
                  className="image-fluid image-width fit_img_threetuk"
                  style={{ height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="our-products">
          <OurProductsSlider />
        </div>

        <div className="NewArrivals">
          <div className="container">
            <p className="main-para">Find your dream bike here</p>
            <img
              src={newArrival_txt}
              className="newArrival_txt"
              alt="new arrival text"
            />
            <h2 className="main-title">New Arrivals</h2>
            <div className="row inner-container">
              <div className="col-md-6 pos-rel">
                <img
                  src={newarivl_topline}
                  className="newarivl_topline"
                  alt="newarivl_topline"
                />
                <img src={homeN1} alt="" className="image-fluid image-width" />
              </div>
              <div className="col-md-6 ">
                <h2 className="block-title">E-Bike E40 (2022)</h2>
                <p className="block-para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </p>
                <p className="block-para">
                  it to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was popularised in the
                  1960s with the release of Letraset sheets containing Lorem
                  Ipsum passages.
                </p>
                <div className="btn-container">
                  <button className="btn viewAll">View Details</button>
                </div>
              </div>
            </div>
            <div className="row inner-container">
              <div className="col-md-6">
                <h2 className="block-title">E-Bike E40 (2022)</h2>
                <p className="block-para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </p>
                <p className="block-para">
                  it to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was popularised in the
                  1960s with the release of Letraset sheets containing Lorem
                  Ipsum passages.
                </p>
                <div className="btn-container">
                  <button className="btn viewAll">View Details</button>
                </div>
              </div>
              <div className="col-md-6 pos-rel">
                <img
                  src={newArrival_btmline}
                  className="newArrival_btmline"
                  alt="newArrival_btmline"
                />
                <img src={homeN2} alt="" className="image-fluid image-width" />
              </div>

              <div className="col-lg-12 text-center mt-5">
                <div className="btn-container ">
                  <Link to="/new-arrival" className="btn viewAll px-5">
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-products">
          <Upcoming_PreOrder />
        </div>
        <div className="work-container">
          <div className="container">
            <p className="main-para">Find your dream bike here</p>
            <h2 className="main-title">How it works</h2>
            <div className="container">
              <div className="video-container">
                <img src={htWork1} alt="" className="image-fluid image-width" />
              </div>
            </div>
          </div>
        </div>

        <div className="News">
          <div className="container">
            <img src={LatestNews} className="LatestNews" alt="LatestNews" />
            <p className="main-para">Find your dream bike here</p>
            <h2 className="main-title">Latest News</h2>

            {newsData?.map((item, index) => {
              if (index !== newsData.length - 1) {
                console.log(index, "index");
              } else {
                console.log("last one", index);
              }
              return (
                <div className="news-letter pos-rel" key={index}>
                  <div className="news-img">
                    {index % 2 !== 0 || index == newsData.length - 1 ? (
                      <></>
                    ) : (
                      <img
                        src={item.image_line}
                        alt="new_line_b "
                        className="new_line_b"
                      />
                    )}

                    <img
                      src={item?.img}
                      alt=""
                      className="image-fluid image-width"
                    />
                  </div>
                  <div className="news-card">
                    <div className="cardHeader">
                      <div className="crd_head">
                        <h4 className="cardTitle">
                          Sed ut perspiciatis unde omnis iste
                        </h4>

                        <small className="">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="social-color icon-style"
                          />{" "}
                          <span className="ml-3">Nov 7 2021</span>
                        </small>
                      </div>
                    </div>
                    <p className="cardPara">
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                      corrupti quos dolores et quas molestias excepturi sint
                      occaecati cupiditate non provident, similique sunt in
                      culpa qui officia deserunt mollitia animi, id est laborum
                      et dolorum fuga. Et harum quidem rerum facilis est et
                      expedita distinctio.
                    </p>
                    <Link className="text-dark" to="/">
                      View Details
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <Subscribe />
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Home;
