import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import SideBar from '../../components/SideBar/SideBar'
import '../../assets/css/Profile.css'
import { cartp, Userpic } from '../../constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import '../../assets/css/MyOrder.css'
import '../../assets/css/WishList.css'
import ViewOrderModal from '../../components/Modal/ViewOrderModal'

function Wishlist() {
    const [spinLoad, setSpinLoad] = useState(false);
    const [selectedData, setSelectedData] = useState();
    
  
  
  const myOrdersData = [
      {
        id:1,
        order_amount: '100',
        order_status: 'Pending',
        price: '100',
        name: 'Product1',
        qty: 2,
      },
      {
        id:2,
        order_amount: '100',
        order_status: 'Pending',
        price: '100',
        name: 'Product2',
        qty: 2,
      },
      {
        id:3,
        order_amount: '100',
        order_status: 'Pending',
        price: '100',
        name: 'Product3',
        qty: 2,
      },
  ]
  
    
      
  return (
   <>
        <Header bg={true}/>
            <div className='url-head'>
                <p className='title'>Wishlist</p>
            </div>

        <div className="dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                    <h2 className="page-name">Wishlist</h2>
                    <SideBar />
                    </div>
                    <div className="col-md-9">
                        <div className="Profile change-password">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="header">
                                <div className="flex-style">
                                    <div className="profile-img">
                                        <img
                                            src={Userpic}
                                            alt=""
                                            className="image-fluid image-width"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p className="name">John Doe</p>
                                    <p className="sub-title">Lorem Ipsum</p>
                                </div>
                            </div>
                        </div>
                        <section className="bookmark">
                <div className="container">
                    <h3 className='main-title'>Wishlist</h3>
                  <div className="tableDv">
                  {spinLoad ? (
                      <>
                      <div className="loader-container">
                        {/* <SpinnerCircular size="80px" color="#d60012" /> */}
                      </div>
                      </>
                      ) : (
                        <>
                    <table>
                      <thead>
                        <tr className="topbar">
                          <th className="productDetail">PRODUCT NAME</th>
                          <th className="Proprice">PRICE</th>
                          <th className="Proprice">AVALABLITY</th>
                          <th className="addcart">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myOrdersData?.map((item,index)=>{
                          return(
                            <tr className="products bookmark" key={item?.id}>
                            <td className="productDetail">
                              <div className="product-detail">
                                <div className="deleteBtn"
                                //    onClick={() => DeleteWishListHandler(item?.product?.id)}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="imgBox">
                                  <figure>
                                    <img
                                     src={cartp}
                                      className="image-fluid image-width"
                                      alt=""
                                    />
                                  </figure>
                                </div>
                                <div>
                                  <p className="ProductName">
                                   {item?.name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="ProductName">${item?.price}</p>
                            </td>
                            <td>
                              <p className="ProductName">IN STOCK</p>
                            </td>
                            <td className="addcart">
                              <div className="ActionBtn">
                                <div className="cartBtn">
                                  <button
                                    type="button"
                                    className="btn addToCart-btn"
                                    // onClick={() => AddToCartHandler(item)}
                                  >
                                    Add to cart
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                          )
                        })}
                       
                      </tbody>
                    </table>
                    <div className="pagination-container mt-5">
                        {/* <ReactPaginate
                          previousLabel="<<"
                          nextLabel=">>"
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-center"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        /> */}
                      </div>
                        </>
                      )
                    }
                   
                  </div>
                  {/* <div className="row align-items-baseline">
                    <div className="col-md-8">
                      <div className="button-group">
                        <Link to="/" className="btn">
                          Continue Shopping
                        </Link>
                        <a 
                          className="btn grey"
                        //   onClick={(e) => ClaerBookmarkHandler(e)}
                        >
                          Clear Bookmarks
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div> */}
                </div>
              </section>
                     
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
       
   </>
  )
}

export default Wishlist