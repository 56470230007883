import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
// import { toast } from "react-toastify";
const stripePromise = loadStripe(
  "pk_test_TYooMQauvdEDq54NiTphI7jx"
);
//

const CheckoutForm = (props) => {
//   const { StepTwoContinue, handleNext, setCardToken, cardToken } = props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
//   const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    console.log(cardElement)
    const payload = await stripe.createToken(cardElement);

  //   const datA = stripe
  // .createPaymentMethod({
  //   type: 'card',
  //   card: cardElement,
  //   billing_details: {
  //     name: 'Jenny Rosen',
  //   },
  // })
  // .then(function(result) {
  //   console.log(result)
  // });
 
    // setCardToken(payload?.token?.id);
    console.log("check", payload);
    if (payload?.token?.id) {
    //   handleNext(event);
      return;
    } else {
    //   toast.error("Something Went Wrong");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  return (
    <>
      <form style={{padding:"0rem 0 0 0",borderRadius:"4px"}} onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />
      
        <ul className="list-inline">
          <li>
            <button
              type="submit"
              // style={{background:"transparent" , color:"black" , width:"100%", padding:"1rem", borderRadius:"100px",border:"2px solid"}}
              className="default-btn stripePay-Btn"
              disabled={!stripe || !elements || paybutton}
              onClick={props.handleClickOpen}
            >
               Add To Card
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

const StripeForm = (props) => {
//   const {   } = props;
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm  handleClickOpen={props.handleClickOpen}
      />

    </Elements>
  );
};

export default StripeForm;