import React from "react";
import { aboutBanner, feature_img } from "../../constant";
import "../../assets/css/Features.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

const Features = () => {
  let arr = [
    {
      heading: "Lorem Ipsum Dummy Text",
      description_one:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      description_two:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
    },
  ];
  return (
    <>
      <Header bg={false} />
      <div className="banner about">
        <img src={aboutBanner} alt="" className="image-fluid image-width" />
        <div className="banner-contentAbout">
          <div className="banner-inner">
            <h2 className="banner-titleAbout">Features</h2>
          </div>
        </div>
      </div>
      <section className="features_page py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <p className="headings">{arr[0].heading}</p>
              <div className="border-btm-gry"></div>
              <p className="text_all mt-4">{arr[0].description_one}</p>
              <p className="text_all mt-4">{arr[0].description_two}</p>
              <button className="btn btn-light learn_more_btn mt-4">
                Learn more
              </button>
            </div>
            <div className="col-lg-6 text-center ps-5">
              <div className="img_box frst">
                <div
                  style={{ backgroundImage: `url("${feature_img}")` }}
                  className="bg_images_content"
                ></div>
                <div className="line">
                  <span className="dot"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-6 text-center pe-5 mt-5 pt-5">
              <div className="img_box scnd">
                <div
                  style={{ backgroundImage: `url("${feature_img}")` }}
                  className="bg_images_content"
                ></div>
                <div className="line">
                  <span className="dot"></span>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-5 pt-5">
              <p className="headings">{arr[0].heading}</p>
              <div className="border-btm-gry"></div>
              <p className="text_all mt-4">{arr[0].description_one}</p>
              <p className="text_all mt-4">{arr[0].description_two}</p>
              <button className="btn btn-light learn_more_btn mt-4">
                Learn more
              </button>
            </div>

            <div className="col-lg-6 mt-5 pt-5">
              <p className="headings">{arr[0].heading}</p>
              <div className="border-btm-gry"></div>
              <p className="text_all mt-4">{arr[0].description_one}</p>
              <p className="text_all mt-4">{arr[0].description_two}</p>
              <button className="btn btn-light learn_more_btn mt-4">
                Learn more
              </button>
            </div>
            <div className="col-lg-6 text-center ps-5 mt-5 pt-5">
              <div className="img_box frst">
                <div
                  style={{ backgroundImage: `url("${feature_img}")` }}
                  className="bg_images_content"
                ></div>
                <div className="line">
                  <span className="dot"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Features;
