import React from 'react';
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { thankyou_bike } from '../../constant'
import '../../assets/css/ThankYou.css';
import Subscribe from '../../components/Subscribe/Subscribe';

const ThankYou = () => {

    const order_details = [
        {
            img: thankyou_bike,
            order_id: "1069",
            product_name: "E- Bike #106",
            product_quantity: "1",
            total_amout: "$500"
        }
    ]

    return (
        <div className='ThankYou'>
            <Header bg={true} />
            <section className='thankyou_content'>
                <div className='container'>
                    <div className='row'>
                        {order_details.map((item, index) => {
                            return (
                                <div className='col-lg-12 pl-5 mt-4'>
                                    <div className='thankyou_main_div'>
                                        <p className='Thank_you_heading'>Thank You!</p>
                                        <p className='mb-1 thnk_para'>Your order ( ID #{item.order_id}) has been Placed</p>
                                        <p className='thnk_para'>it will be delivered in 2-12 business days.</p>
                                        <div className='d-flex mt-5'>
                                            <div >
                                                <img className='prdo_img' src={`${item.img}`} alt="thankyou_bike" />
                                            </div>
                                            <div className='thankyou_greydiv'>
                                                <p>Order ID # ${item.order_id}</p>
                                                <div className='flx-thnkyou'>
                                                    <p>{item.product_name}</p>
                                                    <p>X {item.product_quantity}</p>
                                                </div>
                                                <div className='brdr-btm-line'></div>
                                                <div className='flx-thnkyou'>
                                                    <p>Total Amout</p>
                                                    <p>{item.total_amout}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>
            <Subscribe />
            <Footer />
        </div>
    )
}

export default ThankYou;