import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Subscribe from '../../components/Subscribe/Subscribe';
import '../../assets/css/Auth.css'

const ForgotPasswordOtp = () => {
    return (
        <div className='Auth'>
            <Header bg={true} />
            <section className='Auth_top_section'>
                <div className='container'>
                    <div className='row py-3'>
                        <div className='col-lg-12 text-center my-4'>
                            <p className='main_auth_heading mb-0'>Forgot Password</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='Auth_form_section py-forgotpassword'>
                <div className='container'>
                    <div className='row'>
                        <div className='offset-lg-4 col-lg-4'>
                            <p className='submain_auth_heading text-center '>Enter OTP</p>
                            <p className=' please_enter_email'>Enter 4 digit code here</p>
                            
                            <input type='text' className='auth_inpt_txt text-center mt-4' placeholder='' />
                           
                            <button className='btn btn-light btn-auth mt-4'>
                                Submit 
                            </button>
                            
                            <div className='auth_flx mt-4'>
                                <p>By Creating Account  you are agree to our</p>
                                <p>Terms & conditions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe />
            <Footer />
        </div>
    )
}

export default ForgotPasswordOtp;