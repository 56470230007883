import React from "react";
import Footer from "../../components/Footer/Footer";
import Subscribe from "../../components/Subscribe/Subscribe";
import Header from "../../components/Header/Header";
import { aboutBanner, Userpic } from "../../constant";
import "../../assets/css/NewArrival.css";
import { arrival1 } from "../../constant";
import { arrival2 } from "../../constant";
import { arrival3 } from "../../constant";

function NewArrival() {
  return (
    <>
      <Header bg={false} />
      <div className="banner about">
        <img src={aboutBanner} alt="" className="image-fluid image-width" />
        <div className="banner-contentAbout">
          <div className="banner-inner">
            <h2 className="banner-titleAbout">NEW ARRIVAL</h2>
          </div>
        </div>
      </div>

      <section className="image_content_sec">
        <div className="container">
          <div className="row align-items-center mb-5 pb-5 frst_row">
            <div className="col-md-6">
              <div className="img_box">
                <figure>
                  <img className="img-fluid" src={arrival1} />
                </figure>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content_wrapper">
                <div className="title">
                  <h4>E-Bike E40 (2022)</h4>
                </div>
                <div className="wrapper">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    <br />
                    <br />
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages.
                  </p>
                </div>
                <div class="button-group">
                  <a className="btn" href="#">
                    View Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5 pb-5 scnd_row">
            <div className="col-md-6">
              <div className="content_wrapper">
                <div className="title">
                  <h4>E-Bike E40 (2022)</h4>
                </div>
                <div className="wrapper">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    <br />
                    <br />
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages.
                  </p>
                </div>
                <div class="button-group">
                  <a className="btn" href="#">
                    View Details
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img_box">
                <figure>
                  <img className="img-fluid" src={arrival2} />
                </figure>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5 pb-5 pt-5 mt-5 frst_row">
            <div className="col-md-6">
              <div className="img_box">
                <figure>
                  <img className="img-fluid" src={arrival3} />
                </figure>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content_wrapper">
                <div className="title">
                  <h4>E-Bike E40 (2022)</h4>
                </div>
                <div className="wrapper">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    <br />
                    <br />
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages.
                  </p>
                </div>
                <div class="button-group">
                  <a className="btn" href="#">
                    View Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Subscribe />
      <Footer />
    </>
  );
}

export default NewArrival;
