import React from "react";
import '../../assets/css/SubscribeComponent.css';
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Subscribe = () => {
    return (
        <div className="subscribe_component">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <p className="subscribe_heading">Subscribe</p>
                        <p className="txt">To Our Newsletters System Now To Get Latest News For Us </p>
                        <input type='text' placeholder="Enter Your Email" className="inpt-fld" />
                        <FontAwesomeIcon
                            icon={faPaperPlane}
                            className="subscribe_mailicon"
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Subscribe;