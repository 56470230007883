import React from "react";
import { Link } from "react-router-dom";

function SideBar() {
  let currentUrl = window.location.href.split("/");
  console.log("currentUrl", currentUrl);
  return (
    <>
      <div className="sidebar">
        <ul>
          <li className={`${currentUrl?.[3] === "profile" ? "active" : null}`}>
            <Link to="/profile" 
              className={`${currentUrl?.[3] === "profile" ? "active-link" : null}`}
            >My Profile</Link>
          </li>
          <li className={`${currentUrl?.[3] === "security" ? "active" : null}`}>
            <Link to="/security"
              className={`${currentUrl?.[3] === "security" ? "active-link" : null}`}
            >Change Password</Link>
          </li>
          <li className={`${currentUrl?.[3] === "my-address" ? "active" : null}`}>
            <Link to="/my-address"
               className={`${currentUrl?.[3] === "my-address" ? "active-link" : null}`}
            >My Address</Link>
          </li>
          <li className={`${currentUrl?.[3] === "my-orders" ? "active" : null}`}>
            <Link to="/my-orders"
               className={`${currentUrl?.[3] === "my-orders" ? "active-link" : null}`}
            >My orders</Link>
          </li>
          {/* <li className={`${currentUrl?.[3] === "my-wishlist" ? "active" : null}`}>
            <Link to="/my-wishlist"
               className={`${currentUrl?.[3] === "my-wishlist" ? "active-link" : null}`}
            > My Wishlist</Link>
          </li> */}
          <li className={`${currentUrl?.[3] === "logout" ? "active" : null}`}>
            <Link to="/"
               className={`${currentUrl?.[3] === "logout" ? "active-link" : null}`}
            >Logout</Link>
          </li>
         
        </ul>
      </div>
    </>
  );
}

export default SideBar;
