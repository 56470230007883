import React, { useState } from 'react';
import '../../assets/css/Reviews.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Rating } from 'react-simple-star-rating'
import Footer from '../../components/Footer/Footer';
import { aboutBanner } from '../../constant';
import Header from '../../components/Header/Header';

const Reviews = () => {
    const [rating, setRating] = useState(0); 
    const handleRating = (rate) => {
        setRating(rate)
      }

    let reviews = [
        {
            heading: "Dec 1, 2021 by Alexey Mashentsev and Regina Kim, on Right at Home",
            description: "Both John and I want to thank you so much for all of your diligent work on selling our home and helping us select the replacement. As you know, we are recommending you to everyone we know! Thank you also for the lovely gift basket with all the goodies."
        },
        {
            heading: "Dec 1, 2021 by Polina Antonovsky and Aslan Russ Millcreek, Vaughan on Right at Home",
            description: "We wish to compliment Victoria and Andrey Zibert for the professional and timely manner in which they conducted the sale of our home. Andrey and Victoria learned we were selling and stopped by and asked to be considered for the job. We were very impressed with their salesmanship and enthusiasm. When we signed the contract, all the requirements, technicalities and options were clearly explained. All necessary procedures and inspections were arranged by the Ziberts and we were continually updated.  We highly recommend the Zibert team to anyone considering the sale of property."
        },
        {
            heading: "Dec 1, 2021 by Olena Vitrova on Right at Home",
            description: "Only once in a great while do you do business with people who go truly gar beyond what is expected of them. But Andrey and Victoria are some of those rare, exceptional people. Their skills and professionalism in real estate put them in a class all their own. They blend the magic ingredient of quality salesmanship, aggressive marketing and authentic personal caring into a highly motivated real estate package that generates exciting results. As recent clients of the Zibert's, we would suggest that you look no further for a Real Estate Agent, we have found the best, what's the point of continuing to look."
        }
    ]

    console.log(rating);

    return (
        <>
             <Header bg={false}/>
        <div className='banner about'>
            <img src={aboutBanner} alt="" className='image-fluid image-width' />
                <div className='banner-contentAbout'>
                <div className='banner-inner'>
                    <h2 className='banner-titleAbout'>Features</h2>
                </div>
            </div>
        </div>
        <div className='reviews_page pb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>


                        <p className='main_heading my-5'>What are Our Clients Saying</p>

                        {reviews.map((item, index) => {
                            return (
                                <div key={index} className='single_review'>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faTwitter}
                                            className="icon_rating"
                                        />
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faTwitter}
                                            className="icon_rating"
                                        />
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faTwitter}
                                            className="icon_rating"
                                        />
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faTwitter}
                                            className="icon_rating"
                                        />
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faTwitter}
                                            className="icon_rating"
                                        />
                                    </span>
                                    <span className='ml-4 heading_reviews '>
                                        {item.heading}
                                    </span>
                                    <p className='desc_reviews mt-3'>
                                        {item.description}
                                    </p>
                                </div>
                            )
                        })}


                    </div>
                    <div className='col-lg-12'>
                        <p className='add_review'>Add Review</p>
                    </div>
                    <div className='col-lg-12 '>
                        <input type='text' className='review_inpt' placeholder="Name " />
                    </div>
                    <div className='col-lg-12 mt-2'>
                        <input type='text' className='review_inpt' placeholder="Email " />
                    </div>
                    <div className='col-lg-12 mt-3'>
                        <p className='review_label'>Rating</p>
                        <Rating onClick={handleRating} ratingValue={rating} /* Available Props=*/ />
                    </div>
                    <div className='col-lg-12 mt-2'>
                        <p className='review_label'>Review</p>
                        <textarea rows="5" className='textarea_reviews'></textarea>
                    </div>
                    <div className='col-lg-12 mt-5'>
                        <button className='btn btn-light bt-submit-reviews'>
                            Submit
                        </button>
                        <span className='cancel_reviews ml-5'>Cancel</span>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
       
    )
};

export default Reviews;