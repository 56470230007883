import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import SideBar from '../../components/SideBar/SideBar'
import '../../assets/css/Profile.css'
import { Userpic } from '../../constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function Profile() {
    const [editProfile, setEditProfile ] = useState(false)
    const [newFile, setNewFile] = useState("");
    const [fileupload, setFileupload] = useState("");
    const [uploadLoading, setUploadLoading] = useState(false);

  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [ contact, setContact ] = useState();


    const handleImageUpload = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          setNewFile(file);
          setFileupload(reader.result);
        };
        reader.readAsDataURL(file);
        setUploadLoading(true);
      };

      const EditProfile = (e) => {
        setEditProfile(!editProfile)
        e.preventDefault();
        // setLoading(true);
        // if (
        //   !firstName ||
        //   !lastName ||
        //   !contact ||
        //   !city ||
        //   !zipCode ||
        //   !gender
        // ) {
        //   setLoading(false);
        //   toast.error("Please Enter All Field");
        //   return;
        // }
        // let data = new FormData();
        // data.append("f_name", firstName);
        // data.append("l_name", lastName);
        // data.append("phone", contact);
        // data.append("street_address", streetAddress);
        // data.append("city", city);
        // data.append("zip", zipCode);
        // data.append("gender", gender);
        // data.append("image", newFile);
        // console.log(data);
        // UpdateProfile(data, Token)
        //   .then((res) => {
        //     setLoading(false);
        //     setUploadLoading(false);
        //     toast.success(res?.data?.message);
        //     console.log(res);
        //     dispatch(EditProfileData(res?.data?.data?.user));
        //     setEditProfile(!editProfile)
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setLoading(false);
        //     setUploadLoading(false);
        //   });
      };
    
      
  return (
   <>
        <Header bg={true}/>
            <div className='url-head'>
                <p className='title'>My Profile</p>
            </div>

        <div className="dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                    <h2 className="page-name">My Profile</h2>
                    <SideBar />
                    </div>
                    <div className="col-md-9">
                        <div className="Profile">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                            <div className="header">
                                <div className="flex-style">
                                    <div className="profile-img">
                                        <img
                                            src={Userpic}
                                            alt=""
                                            className="image-fluid image-width"
                                        />
                                    </div>
                                    <div className="edit-container">
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            className="edit-pen-icon"
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                            multiple="false"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p className="name">John Doe</p>
                                    <p className="sub-title">Lorem Ipsum</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                            <div className="save-container">
                            {editProfile ? (
                                <button type="button" className="btn saveBtn btn-primary"
                                    onClick={(e) => EditProfile(e)}
                                    // disabled={loading}
                                >
                               Save
                            </button>
                            ) : (
                                <button type="button" className="btn saveBtn btn-primary"
                                onClick={()=>setEditProfile(!editProfile)}
                                >
                            Edit Profile
                            </button>
                            )}
                            
                            </div>
                        </div>

                    <div className="row user-fields">
                        {
                        editProfile ? (
                            <>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <p className="input-label">First Name</p>
                        <input 
                            type="text" 
                            placeholder="First Name" 
                            value={firstName}
                            onChange={(e)=>setFirstName(e.target.value)}
                        />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <p className="input-label">Last Name</p>
                        <input 
                            type="text" 
                            placeholder="Last Name" 
                            value={lastName}
                            onChange={(e)=>setLastName(e.target.value)}
                        />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <p className="input-label">Email Address</p>
                        <input 
                            type="text" 
                            placeholder="Email Address" 
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            disabled={true}
                        />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <p className="input-label">Contact</p>
                        <input 
                            type="text" 
                            placeholder="Contact" 
                            value={contact}
                            onChange={(e)=>setContact(e.target.value)}
                        />
                        </div>
                       
                    
                            </>
                        ) : (
                            <>
                           
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <div className="info-container">
                            <p className="input-label">First Name</p>
                            <p className="info">John</p>
                        </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <div className="info-container">
                            <p className="input-label">Last Name</p>
                            <p className="info">Doe</p>
                        </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <div className="info-container">
                            <p className="input-label">Email Address</p>
                            <p className="info">john@mail.com</p>
                        </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 mt-4">
                        <div className="info-container">
                            <p className="input-label">Contact</p>
                            <p className="info">+00 123 456 789</p>
                        </div>
                        </div>
                            </>
                        )
                        }

                    </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
   </>
  )
}

export default Profile