import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Subscribe from '../../components/Subscribe/Subscribe';
import '../../assets/css/Auth.css'

const Signin = () => {
    return (
        <div className='Auth'>
            <Header bg={true} />
            <section className='Auth_top_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 text-center my-4'>
                            <p className='main_auth_heading'>Sign In</p>
                            <p className='main_auth_text'>Not a Member ? <span className='main_auth_text_span'>Sign up</span></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='Auth_form_section py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='offset-lg-4 col-lg-4'>
                            <p className='auth_label'>Email</p>
                            <input type='text' className='auth_inpt_txt' placeholder='Enter Enail Here' />
                            <p className='auth_label'>Password</p>
                            <input type='password' className='auth_inpt_txt' placeholder='Enter Enail Here' />
                            <button className='btn btn-light btn-auth mt-4'>
                                Login
                            </button>
                            <div className='auth_flx mt-3'>
                                <div>
                                    <input type='checkbox' />  <label>Remember Me</label>
                                </div>
                                <p>Forgot Password</p>
                            </div>
                            <div className='auth_flx'>
                                <p>By Creating Account  you are agree to our</p>
                                <p>Terms & conditions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe />
            <Footer />
        </div>
    )
}

export default Signin;