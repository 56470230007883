import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import {
  aboutBanner,
  product1,
  product2,
  product3,
  product4,
  product5,
} from "../../constant";
import "../../assets/css/Shop.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";

function Shop() {
  const Navigate = useNavigate();
  const cateData = [
    {
      id: 1,
      name: "City",
    },
    {
      id: 2,
      name: "customize",
    },
    {
      id: 3,
      name: "Electric Bikes",
    },
    {
      id: 4,
      name: "Kids bikes",
    },
    {
      id: 5,
      name: "Mountain",
    },
    {
      id: 6,
      name: "Road",
    },
  ];

  const sizeData = [
    {
      id: 1,
      size: "50",
    },
    {
      id: 2,
      size: "52",
    },
    {
      id: 3,
      size: "54",
    },
    {
      id: 4,
      size: "56",
    },
    {
      id: 1,
      size: "58",
    },
    {
      id: 1,
      size: "60",
    },
  ];

  const productData = [
    {
      id: 1,
      img: product1,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 2,
      img: product2,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 3,
      img: product3,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 4,
      img: product4,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 5,
      img: product5,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 6,
      img: product2,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 7,
      img: product4,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 8,
      img: product3,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
    {
      id: 9,
      img: product1,
      title: "Lorem Ipsum 9.6",
      price: "$1999.99",
    },
  ];

  const colorData = [
    {
      id: 1,
      color: "Blue",
      code: "#0d68b7",
    },
    {
      id: 2,
      color: "Green",
      code: "#0db70f",
    },
    {
      id: 3,
      color: "Orange",
      code: "#df8c2e",
    },
    {
      id: 4,
      color: "Red",
      code: "#af1c09",
    },
    {
      id: 5,
      color: "yellow",
      code: "#e5e42a",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }

  const minDistance = 10;

  const [value1, setValue1] = React.useState([20, 37]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  return (
    <>
      <Header bg={false} />
      <div className="banner about">
        <img src={aboutBanner} alt="" className="image-fluid image-width" />
        <div className="banner-contentAbout">
          <div className="banner-inner">
            <h2 className="banner-titleAbout">Shop</h2>
          </div>
        </div>
      </div>

      <section className="shop">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="shop-left">
                <div className="cate-container">
                  <h2 className="cate-title">Categories</h2>
                  <ul>
                    {cateData?.map((item, index) => {
                      return <li className="cate-sub">{item.name}</li>;
                    })}
                  </ul>
                </div>
                <hr />
                <div className="price-container">
                  <h2 className="cate-title">Price</h2>
                  <div>
                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={value1}
                      onChange={handleChange1}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      disableSwap
                    />
                  </div>
                  <div className="price-footer">
                    <div className="price-footerInner">
                      <p>Min Price: </p>
                      <p>1000$</p>
                    </div>
                    <div className="price-footerInner">
                      <p>Max Price: </p>
                      <p>1000$</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="size-container">
                  <h2 className="cate-title size-title">Size</h2>
                  <ul>
                    {/* <li className='size-title'>Size</li> */}

                    {sizeData?.map((item, index) => {
                      return <li className="size-sub">{item.size}</li>;
                    })}
                  </ul>
                </div>
                <hr />
                <div className="color-container">
                  <h2 className="cate-title color-title">Color</h2>
                  <ul>
                    {/* <li className='color-title'>Color</li> */}

                    {colorData?.map((item, index) => {
                      return (
                        <div className="color-dv">
                          <div
                            className="color-box"
                            style={{ backgroundColor: `${item?.code}` }}
                          ></div>
                          <li className="color-sub">{item.color}</li>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="shop-right">
                <div className="result-container">
                  <p className="result-text">Showing 1–16 of 17 results</p>
                  <p className="break">|</p>
                  <select name="cars" id="cars">
                    <option value="volvo">default sorting</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>

                <div className="row">
                  {productData?.map((item, index) => {
                    return (
                      <div className="col-md-4 product-card" key={index}>
                        <div
                          className="card h-100"
                          onClick={() => Navigate("/product-details")}
                        >
                          <div className="picture_box">
                            <div className="img-box">
                              <img
                                src={item?.img}
                                alt=""
                                className="image-fluid image-width"
                              />
                            </div>
                            <div className="hover_box">
                              <div className="wishlist_icon">
                                <button>
                                  <i
                                    class="fa fa-heart-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                              <div className="button-group">
                                <Link to="/">View Detail</Link>
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <h4 className="title">{item?.title}</h4>
                            <p className="price">{item?.price}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Shop;
