import React from 'react';
import Slider from "react-slick";
import { upcoming_bikes1, upcoming_bikes2 } from '../../constant';


const Upcoming_PreOrder = () => {

    function SampleNextArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className='Slider_prod_custom_arrows-next'
                // style={{ ...style, display: "block", background: "red", height: "180px", width: "180px" }}
                onClick={onClick}
            />
        );
    }


    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className="Slider_top_custom_arrows"
                // style={{ ...style, display: "block", background: "green", height: "180px", width: "180px" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        // arrows: false,
        dots: true,
        infinite: true,
        fade: true,
        // speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const BannerData = [
        {
            id: 1,
            img: upcoming_bikes1,
            title: "Lorem Ipsum Dolor, Sit Amet",
            desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
            id: 2,
            img: upcoming_bikes2,
            title: "Lorem Ipsum Dolor, Sit Amet",
            desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
            id: 3,
            img: upcoming_bikes1,
            title: "Lorem Ipsum Dolor, Sit Amet",
            desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
    ];

    return (
        <div className='Upcoming_PreOrder'>
            <Slider {...settings}>
                {BannerData?.map((item, index) => {
                    return (
                        <div key={index}
                        >
                            <div
                                // style={{ backgroundImage: `url("${item?.img}")` }}
                                // className=' '
                                className="banner-slider setbnr_imgs" key={index}
                            >
                                <p className='main-para text-dark pt-0'>Pre-Order</p>
                                <h2 className='main-title text-dark'>Upcoming Bikes</h2>
                                {/* <img src={item?.img} className="image-fluid" alt="" /> */}
                                <div className='container'>
                                    <div className='row mt-5'>
                                        <div className='col-lg-5'>
                                            <div className='bg-img-prod'>
                                                <img src={item.img} className="prd_slider_upcoming" alt="product" />
                                            </div>
                                        </div>
                                        <div className='col-lg-7 align-center'>
                                            {/* <h2 className="title text-dark">{item?.title}</h2> */}
                                            <h2 className="txt-prod-upcoming text-dark">{item?.title}</h2>
                                            {/* <hr className='hr-line' /> */}
                                            <p className="para text-dark">
                                                {item?.desc}
                                            </p>
                                            <button className='btn btn-light bt-preorder'>
                                                Pre-Order
                                            </button>
                                        </div>

                                    </div>
                                </div>


                                {/* <div className="contentDv">
                                    <div className="content-inner">
                                       
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    );
                })}
            </Slider>
        </div>
    )
}

export default Upcoming_PreOrder;