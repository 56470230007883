import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import "../../assets/css/Profile.css";
import { Userpic, tableimg } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../../assets/css/MyOrder.css";
import "../../assets/css/WishList.css";
import ViewOrderModal from "../../components/Modal/ViewOrderModal";

function MyOrders() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };
  const viewOrderHandler = (item) => {
    setIsOpenModal(true);
    setSelectedData(item);
  };

  const myOrdersData = [
    {
      id: "#1069",
      image: tableimg,
      order_amount: "158.07 ",
      order_status: "Pending",
      price: "500 ",
      name: "Product1",
      qty: "01",
    },
    {
      id: "#1069",
      image: tableimg,
      order_amount: "158.07 ",
      order_status: "Pending",
      price: "158.07 ",
      name: "Product2",
      qty: "01",
    },
    {
      id: "#1069",
      image: tableimg,
      order_amount: "158.07 ",
      order_status: "Pending",
      price: "158.07 ",
      name: "Product3",
      qty: "01",
    },
  ];

  return (
    <>
      <Header bg={true} />
      <div className="url-head">
        <p className="title">My Orders</p>
      </div>

      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="page-name">My Orders</h2>
              <SideBar />
            </div>
            <div className="col-md-9">
              <div className="Profile change-password">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="header">
                      <div className="flex-style">
                        <div className="profile-img">
                          <img
                            src={Userpic}
                            alt=""
                            className="image-fluid image-width"
                          />
                        </div>
                        <div className="edit-container">
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="edit-pen-icon"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            multiple="false"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="name">John Doe</p>
                        <p className="sub-title">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>

                  <section className="bookmark">
                    <div className="container">
                      {/* <div className="heading">
                    <h3 className="text-center title font-40 fw-600">
                      My Orders
                    </h3>
                  </div> */}
                      <h3 className="main-title">My Orders</h3>
                      <div className="tableDv">
                        {spinLoad ? (
                          <>
                            <div className="loader-container">
                              {/* <SpinnerCircular size="80px" color="#d60012" /> */}
                            </div>
                          </>
                        ) : (
                          <>
                            <table>
                              <thead>
                                <tr className="topbar">
                                  <th>Order ID</th>
                                  <th>UNIT PRICE</th>
                                  <th>QUANTITY</th>
                                  <th>TOTAL</th>
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                              </thead>

                              {myOrdersData?.map((item, index) => {
                                console.log(item);
                                return (
                                  <tr className="products" key={item?.id}>
                                    <td>
                                      <h5 className="ProductName d-flex align-items-center">
                                        <img
                                          src={item?.image}
                                          className="img-fluid"
                                        />
                                        <span className="ml-3">
                                          {" "}
                                          {item?.id}
                                        </span>
                                      </h5>
                                    </td>
                                    <td>
                                      <h5 className="ProductName">
                                        $ {item?.order_amount}
                                      </h5>
                                    </td>
                                    <td>
                                      <h5 className="qty">{item?.qty}</h5>
                                    </td>
                                    <td>
                                      <h5 className="TOTAL">$ {item?.price}</h5>
                                    </td>
                                    <td>
                                      <h5 className="ProductName">
                                        {item?.order_status}
                                      </h5>
                                    </td>

                                    <td className="Proprice">
                                    
                                      <a href="/order-details">View Details</a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                            {/* <div className="pagination-container mt-5">
                        <ReactPaginate
                          previousLabel="<<"
                          nextLabel=">>"
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-center"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div> */}
                          </>
                        )}
                      </div>
                      {/* <div className="row align-items-baseline">
                    <div className="col-md-8">
                      <div className="button-group">
                        <Link to="/" className="btn">
                          Continue Shopping
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div> */}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {/* <ViewOrderModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      /> */}
    </>
  );
}

export default MyOrders;
