import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { aboutBanner, aboutVd1, t1, t2, t3, t4 } from '../../constant'
import '../../assets/css/About.css'

function About(props) {
    const teamData = [
        {
            id:1,
            img: t1,
        },
        {
            id:2,
            img: t2,
        },
        {
            id:3,
            img: t3,
        },
        {
            id:4,
            img: t4,
        },
    ]
  return (
   <>
    <Header bg={false}/>
        <div className='banner about'>
            <img src={aboutBanner} alt="" className='image-fluid image-width' />
                <div className='banner-contentAbout'>
                <div className='banner-inner'>
                    <h2 className='banner-titleAbout'>About</h2>
                </div>
            </div>
        </div>
        <section className='about-top'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h2 className='title'>Who We Are</h2>
                        <hr className='hr-line'/>
                        <p className='main-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type 
                            specimen book.
                        </p>
                        <p className='main-para mt-4'>
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece 
                            of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, 
                            a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure 
                            Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in
                             classical literature, discovered the undoubtable source.
                        </p>
                        <div className='btn-container'>
                            <button className='btn learn-btn'>Learn more</button>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <img src={aboutVd1} alt="" className='image-fluid image-width' />
                    </div>
                </div>
            </div>
        </section>

        <section className='mission-main'>
            <div className='container'>
                <div className='row'>
                <div className='col-md-6'>
                    <div className='mission-card'>
                        <h3 className='card-title'>Our Mission</h3>
                        <hr className='hr-line' />
                        <p className='card-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took a galley of type and scrambled it to make a type 
                            specimen book.</p>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='mission-card'>
                        <h3 className='card-title'>Our Vission</h3>
                        <hr className='hr-line' />
                        <p className='card-para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                            when an unknown printer took a galley of type and scrambled it to make a type 
                            specimen book.</p>
                    </div>
                </div>
                </div>
               
            </div>
        </section>

        <section  className='team'>
            <div className='container'>
                <h2 className='main-title'>Meet Our Team</h2>
                <div className='flex-style'>
                    <hr className='hr-line' />
                </div>
               
                <div className='row team-info'>
                    {teamData?.map((item,index)=>{
                        return(
                            <div className='col-md-3' key={index}>
                                <img src={item.img} alt="" className='image-fluid image-width' />
                            </div>
                        )
                    })}
                </div>
               
              
            </div>
        </section>
    <Footer />
   </>
  )
}

export default About