import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/MyAddress.css";
import "../../assets/css/AddAddress.css";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { Userpic } from "../../constant";
import Footer from "../../components/Footer/Footer";
import AddAddress from "../../components/Addresss/AddAddress";
import AddressCard from "../../components/Addresss/AddressCard";

const MyAddress = () => {
  const [addAddress, setAddAddress] = useState(false);
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };
  return (
    <>
      <Header bg={true} />
      <div className="url-head">
        <p className="title">My Address</p>
      </div>

      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="page-name">My Address</h2>
              <SideBar />
            </div>
            <div className="col-md-9">
              <div className="Profile change-password">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-12">
                    <div className="header">
                      <div className="flex-style">
                        <div className="profile-img">
                          <img
                            src={Userpic}
                            alt=""
                            className="image-fluid image-width"
                          />
                        </div>
                        <div className="edit-container">
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="edit-pen-icon"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            multiple="false"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="name">John Doe</p>
                        <p className="sub-title">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12 addressBtn-container">
                    {addAddress ? (
                      <button
                        className="btn address-btn"
                        onClick={() => setAddAddress(!addAddress)}
                      >
                        Save Address
                      </button>
                    ) : (
                      <button
                        className="btn address-btn"
                        onClick={() => setAddAddress(!addAddress)}
                      >
                        Add New Address
                      </button>
                    )}
                  </div>
                  {addAddress ? (
                    <>
                      <AddAddress />
                    </>
                  ) : (
                    <>
                      <AddressCard />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MyAddress;
