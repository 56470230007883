import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { aboutBanner, location_img, mail_img, Phone_img,  } from '../../constant'
import '../../assets/css/Contact.css'

function Contact() {
    let contact = [
        {
            image: Phone_img,
            heading: "PHONE",
            text: "+91 123 456 7890"
        },
        {
            image: mail_img,
            heading: "EMAIL",
            text: "support@swiftebikes.com"
        },
        {
            image: location_img,
            heading: "LOCATION",
            text: "1234 North Avenue Luke Lane, South Bend, 360001"
        },
    ]
  return (
   <>
    <Header />
        <div className='banner about'>
            <img src={aboutBanner} alt="" className='image-fluid image-width' />
                <div className='banner-contentAbout'>
                <div className='banner-inner'>
                    <h2 className='banner-titleAbout'>Contact</h2>
                </div>
            </div>
        </div>
        <div className='contact'>
            <div className='container'>
                <h2 className='main-title'>Contact Form</h2>
                <div className='row'>
                    <div className='col-md-6'>
                        <input 
                            type="text"
                            placeholder='First Name'
                        />
                    </div>
                    <div className='col-md-6'>
                        <input 
                            type="text"
                            placeholder='Last Name'
                        />
                    </div>
                    <div className='col-md-6'>
                        <input 
                            type="text"
                            placeholder='E-mail address'
                        />
                    </div>
                    <div className='col-md-6'>
                        <input 
                            type="text"
                            placeholder='Phone'
                        />
                    </div>
                    <div className='col-md-12'>
                        <textarea 
                         type="text"
                            placeholder='Your message here…'
                        ></textarea>
                    </div>
                    <div className='col-md-12'>
                        <button className='btn submit mt-4'>Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <section className='contact_component'>
            <div className='container'>
                <div className='row'>
                    {
                        contact.map((item, index) => {
                            return (
                                <div key={index} className='col-lg-4'>
                                    <div className='contact_box'>
                                        <img className='contact_img' src={item.image} />
                                        <div>
                                            <p className='contact_heading'>{item.heading}</p>
                                            <p className='contact_text'>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    <Footer />
   </>
  )
}

export default Contact