import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { headlogo } from "../../constant";
import "../../assets/css/Footer.css";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <>
      <footer className="footer cover-banner">
        <div className="container">
          <div className="inner-container">
            <div className="row pb-3">
              <div className="col-md-4 widgets2 border-right mb-4">
                <div className="footer-logo">
                  <img src={headlogo} className="image-fluid" alt="" />
                </div>
                <h4 className="pb-3 first-col footer-lorem">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </h4>
                <div className="row">
                  <div className="col-lg-5 text-left">
                    <h4
                      className="pb-3 text-white mainText"
                      style={{ textAlign: "left" }}
                    >
                      Contact
                    </h4>
                    <p className="mainEmail" style={{ textAlign: "left" }}>
                      info@swiftebikes.com
                    </p>
                  </div>
                  <div className="col-lg-7">
                    <div className="social-icons">
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        className="social-color"
                      />
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="social-color"
                      />
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="social-color"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 widgets2 border-right mb-4">
                <h4 className="pb-3 text-white mainText">Quick links</h4>
                <div className="quick-link">
                  <ul>
                    <li>
                      <Link to="/about-us"> About Us </Link>
                    </li>
                    <li>
                      <Link to="/features"> Features </Link>
                    </li>
                    <li>
                      <Link to="/shop"> Shop </Link>
                    </li>
                    <li>
                      <Link to="/faqs"> FAQ </Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="/reviews"> Reviews </Link>
                    </li>
                    <li>
                      <Link to="/latest-news"> Latest News </Link>
                    </li>
                    <li>
                      <Link to="/new-arrival"> New Arrival </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <Link to="/privacy-policy"> Privacy & Policy </Link>
                    </li>
                    <li>
                      <Link to="/term-condition"> Terms & Conditions </Link>
                    </li>
                    <li>
                      <Link to="/return-policy"> Return Policy </Link>
                    </li>
                    {/* <li>
                      <Link to="/cookie-policy">Newsletter Subscription</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-md-4 widgets2">
                
              
              </div> */}
              <div className="col-md-12 mt-3">
                {/* <hr className="hr-line" /> */}
                <div className="brdr-btm-whiteline"></div>
                <p className="copy-right-text mt-5">
                  Swift e biksers | All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
