import React from 'react';
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { order_details_img } from '../../constant'
import '../../assets/css/Order_Details.css';
import Subscribe from '../../components/Subscribe/Subscribe';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const OrderDetails = () => {

    const order_details = [
        {
            img: order_details_img,
            order_id: "1069",
            product_name: "E- Bike #106",
            product_quantity: "1",
            total_amout: "$500"
        }
    ];

    return (
        <div className='order_detail_page'>
            <Header bg={true} />
            <div className='Auth'>
                <section className='Auth_top_section'>
                    <div className='container'>
                        <div className='row py-3'>
                            <div className='col-lg-12 text-center my-4'>
                                <p className='main_auth_heading mb-0'>Order Detail</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className='order_details'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 '>
                            <div className='order_details_main_div'>
                                <div className='pill'>
                                    <img className='prod_img' src={order_details_img} alt="bike" />
                                </div>
                                <div className='order_details_content'>
                                    <p className='order_id_top'>Order ID#1069</p>
                                    <div className='d-flex'>
                                        <p className='order_id_top'>
                                            E- Bike #106
                                        </p>
                                        <p className='order_id_top ml-5 clr-lightgry'>
                                            X 1
                                        </p>
                                    </div>
                                    <p className='order_details_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>

                                    <div className='d-flex mt-5'>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faStar}
                                                className="star-ico "
                                            />
                                        </div>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faStar}
                                                className="star-ico"
                                            />
                                        </div>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faStar}
                                                className="star-ico"
                                            />
                                        </div>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faStar}
                                                className="star-ico"
                                            />
                                        </div>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faStar}
                                                // star-gry
                                                className="star-ico"
                                            />
                                        </div>
                                    </div>
                                    <div className='brdr-btm-light'></div>
                                    <p className='total_amount'>Total Amount
                                        <span className='ml-5'>$500</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe />
            <Footer />
        </div>
    )
}

export default OrderDetails;